/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { motion } from 'framer-motion'
import ParseContent from 'components/shared/ParseContent'

// Libraries
import parse from 'html-react-parser'

// Elements
import ImageBackground from 'components/elements/ImageBackground'
import BlockDefault from 'components/elements/BlockDefault'

// Language
import { getLanguage } from 'services/language'

// Icons
import Arrow from 'img/icon/arrow-right-circled.inline.svg'

const Requirements = ({ ids }) => {
  const language = getLanguage()
  const [indexOpen, setIndexOpen] = useState(null)

  const setOpen = (index) => {
    setIndexOpen(index === indexOpen ? null : index)
  }

  const { allWordpressWpVoorwaarden: requirements } = useStaticQuery(graphql`
    {
      allWordpressWpVoorwaarden {
        edges {
          node {
            wordpress_id
            wpml_current_locale

            acf {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 400) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              description
              descriptionBig: description_big
              button {
                title
                link {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  let requirementsContent = requirements.edges.filter(
    ({ node: { wpml_current_locale: locale } }) => locale === language
  )

  if (ids) {
    requirementsContent = requirementsContent.filter(
      ({ node: { wordpress_id: wpId } }) => ids.indexOf(wpId) !== -1
    )
  }

  return (
    <div className="row">
      {requirementsContent.map(({ node }, index) => (
        <Requirement
          image={node.acf.image}
          description={node.acf.description}
          descriptionBig={node.acf.descriptionBig}
          button={node.acf.button}
          index={index}
          setOpen={setOpen}
          indexOpen={indexOpen}
        />
      ))}
    </div>
  )
}

const StyledRequirement = styled(BlockDefault)`
  padding: 0 !important;
  overflow: hidden;
`

const Image = styled(ImageBackground)`
  padding-top: 65%;
`

const Content = styled.div`
  background-color: ${(props) => props.theme.color.contrast};
`

const Description = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.light};

  @media (min-width: 992px) {
    & ul {
      min-height: 90px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    & ul li {
      font-size: 14px;
    }
  }

  & ul li {
    &:before {
      background-color: ${(props) => props.theme.color.text.light};
    }
  }
`

const MotionContent = styled(motion.div)`
  overflow: hidden;
`

const Button = styled(motion.div)`
  color: ${(props) => props.theme.color.text.light} !important;
  font-size: ${(props) => props.theme.font.size.l};
  font-weight: ${(props) => props.theme.font.weight.s};
  line-height: ${(props) => props.theme.font.size.l};
  cursor: pointer;

  & strong {
    font-weight: ${(props) => props.theme.font.weight.xl};
  }

  & svg {
    height: 40px;
    width: 40px;
  }

  &:hover {
    color: ${(props) => props.theme.color.text.light};
    text-decoration: underline;
  }
`

const MotionDescription = styled(ParseContent)`
  & p {
    color: ${(props) => props.theme.color.text.light};
  }
`

const Requirement = ({
  image,
  description,
  descriptionBig,
  button,
  index,
  indexOpen,
  setOpen,
}) => {
  const status = indexOpen === index ? 'open' : 'closed'

  return (
    <div className="col-md-4 mb-5 mb-md-0">
      <StyledRequirement
        className={`mx-lg-4 ${status === 'open' ? 'h-100' : 'h-auto'}`}
      >
        <Image src={image} />
        <Content className="py-4 px-4">
          <Description className="pb-3" content={description} />
          <Button
            onClick={() => {
              setOpen(index)
            }}
            className="d-flex align-items-center justify-content-between"
          >
            <div>{parse(button.title)}</div>
            <motion.div
              initial="open"
              variants={{
                open: { rotate: 90 },
                closed: { rotate: 0 },
              }}
              animate={status}
            >
              <Arrow />
            </motion.div>
          </Button>
          <MotionContent
            initial="open"
            variants={{
              open: { opacity: 1, height: 'auto' },
              closed: { opacity: 0, height: 0 },
            }}
            animate={status}
          >
            <div className="mt-3">
              <MotionDescription content={descriptionBig} />
            </div>
          </MotionContent>
        </Content>
      </StyledRequirement>
    </div>
  )
}

export default Requirements
