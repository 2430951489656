/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import React from 'react'
import parse from 'html-react-parser'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

// Data
import homeInfo from 'src/graphql/homeInfo'

const PriceIndication = ({ noInfo = false }) => {
  const homeFields = homeInfo.get()

  return (
    <div>
      <div>
        {parse(homeFields.acf.banner_block.description)}
      </div>
      <div className="mt-4 text-center">
        <ButtonDefault to={homeFields.acf.banner_block.button.url}>
          {homeFields.acf.banner_block.button.title}
        </ButtonDefault>
      </div>
      {!noInfo && (
        <div className="mt-3 text-center font-weight-s">
          {homeFields.acf.banner_block.info}
        </div>
      )}
    </div>
  )
}

export default PriceIndication