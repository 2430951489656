/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

// Language
import { getLanguage } from 'services/language'

import CustomLink from 'components/shared/CustomLink'

const StyledRating = styled.div`
  @media (max-width: 991px) {
    border-bottom: 3px solid #cccccc;
  }
`

const RatingTitle = styled.div`
  color: #0c991f;
`

const RatingStar = styled.div`
  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: #ffbe00;
    }
  }
`

const RatingScore = styled.div`
  max-height: 60px;
  position: relative;
  top: 3px;
  color: ${(props) => props.theme.color.text.main};
`

const RatingScoreNumber = styled.div``

const RatingScoreSeperator = styled.div``

const RatingScoreDecimal = styled.div`
  position: absolute;
  top: 3px;
  left: 45px;
`

const RatingScoreStripe = styled.div`
  width: 1px;
  height: 60%;
  background-color: #6b6b6b;
`

const RatingScoreAmountTitle = styled.div`
  ${(props) =>
    props.noTitle &&
    css`
      font-size: ${(props) => props.theme.font.size.m} !important;
      margin-left: 0.9rem !important;
      line-height: 1.5rem;
    `}

  color: ${(props) => props.theme.color.text.main};
`

const Rating = ({ className = '', noTitle }) => {
  const language = getLanguage()

  const { reviews } = useStaticQuery(graphql`
    {
      reviews: allWordpressWpReview {
        edges {
          node {
            title
            acf {
              name
              stars
              description
            }
          }
        }
      }
    }
  `)

  let number

  const numbers = reviews.edges.map(
    ({ node: { acf } }, index) => (number = acf.stars)
  )

  const reducer = (accumulator, item) => accumulator + item

  // const rating = numbers.reduce(reducer).slice(0,2);
  const rating = 8.5

  const stars = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

  const maxRating = 10

  return (
    <StyledRating>
      <CustomLink to={`${language === 'nl_NL' ? '/reviews/' : '/en/reviews/'}`}>
        <div className={className}>
          <div className="row mx-3">
            <div
              className={`${
                noTitle ? 'd-none' : 'col-lg-4 d-flex align-items-center pr-0'
              }`}
            >
              <RatingTitle className="font-size-xm font-weight-l pl-lg-4 ml-lg-3">
                {language === 'nl_NL' ? 'Gemiddelde score' : 'Average score'}
              </RatingTitle>
            </div>
            <div
              className={`${
                noTitle
                  ? 'col-sm-6 col-lg-6 col-lg-3'
                  : 'col-8 col-sm-6 col-md-5 col-lg-3'
              } d-flex justify-content-between align-items-center px-lg-0`}
            >
              {stars.map((star) => {
                let starType = 'empty'

                if (rating % 1 === 0.5 && Math.ceil(rating) === star) {
                  starType = 'half'
                } else if (star < rating || star === rating) {
                  starType = 'full'
                }

                return (
                  <RatingStar key={star}>
                    {starType === 'empty' && (
                      <svg
                        height="511pt"
                        viewBox="0 -10 511.98685 511"
                        width="511pt"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m114.59375 491.140625c-5.609375 0-11.179688-1.75-15.933594-5.1875-8.855468-6.417969-12.992187-17.449219-10.582031-28.09375l32.9375-145.089844-111.703125-97.960937c-8.210938-7.167969-11.347656-18.519532-7.976562-28.90625 3.371093-10.367188 12.542968-17.707032 23.402343-18.710938l147.796875-13.417968 58.433594-136.746094c4.308594-10.046875 14.121094-16.535156 25.023438-16.535156 10.902343 0 20.714843 6.488281 25.023437 16.511718l58.433594 136.769532 147.773437 13.417968c10.882813.980469 20.054688 8.34375 23.425782 18.710938 3.371093 10.367187.253906 21.738281-7.957032 28.90625l-111.703125 97.941406 32.9375 145.085938c2.414063 10.667968-1.726562 21.699218-10.578125 28.097656-8.832031 6.398437-20.609375 6.890625-29.910156 1.300781l-127.445312-76.160156-127.445313 76.203125c-4.308594 2.558594-9.109375 3.863281-13.953125 3.863281zm141.398438-112.875c4.84375 0 9.640624 1.300781 13.953124 3.859375l120.277344 71.9375-31.085937-136.941406c-2.21875-9.746094 1.089843-19.921875 8.621093-26.515625l105.472657-92.5-139.542969-12.671875c-10.046875-.917969-18.6875-7.234375-22.613281-16.492188l-55.082031-129.046875-55.148438 129.066407c-3.882812 9.195312-12.523438 15.511718-22.546875 16.429687l-139.5625 12.671875 105.46875 92.5c7.554687 6.613281 10.859375 16.769531 8.621094 26.539062l-31.0625 136.9375 120.277343-71.914062c4.308594-2.558594 9.109376-3.859375 13.953126-3.859375zm-84.585938-221.847656s0 .023437-.023438.042969zm169.128906-.0625.023438.042969c0-.023438 0-.023438-.023438-.042969zm0 0" />
                      </svg>
                    )}
                    {starType === 'full' && (
                      <svg
                        height="511pt"
                        viewBox="0 -10 511.99143 511"
                        width="511pt"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m510.652344 185.882812c-3.371094-10.367187-12.566406-17.707031-23.402344-18.6875l-147.796875-13.417968-58.410156-136.75c-4.3125-10.046875-14.125-16.53125-25.046875-16.53125s-20.738282 6.484375-25.023438 16.53125l-58.410156 136.75-147.820312 13.417968c-10.835938 1-20.011719 8.339844-23.402344 18.6875-3.371094 10.367188-.257813 21.738282 7.9375 28.925782l111.722656 97.964844-32.941406 145.085937c-2.410156 10.667969 1.730468 21.699219 10.582031 28.097656 4.757813 3.457031 10.347656 5.183594 15.957031 5.183594 4.820313 0 9.644532-1.28125 13.953125-3.859375l127.445313-76.203125 127.421875 76.203125c9.347656 5.585938 21.101562 5.074219 29.933593-1.324219 8.851563-6.398437 12.992188-17.429687 10.582032-28.097656l-32.941406-145.085937 111.722656-97.964844c8.191406-7.1875 11.308594-18.535156 7.9375-28.925782zm-252.203125 223.722657" />
                      </svg>
                    )}
                    {starType === 'half' && (
                      <svg
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="475.044px"
                        height="475.044px"
                        viewBox="0 0 475.044 475.044"
                      >
                        <g>
                          <path
                            d="M474.487,183.276c-1.711-5.236-6.852-8.52-15.41-9.851l-143.323-20.839L251.52,22.681c-4-7.804-8.661-11.704-13.989-11.704
                    c-5.519,0-10.183,3.9-13.988,11.704l-64.241,129.905L15.978,173.425c-8.564,1.332-13.704,4.615-15.415,9.851
                    c-1.709,5.236,0.478,10.898,6.567,16.989l103.924,101.068L86.501,444.082c-0.95,6.286-0.381,11.173,1.715,14.702
                    c2.092,3.524,5.33,5.283,9.707,5.283c3.237,0,7.043-1.14,11.42-3.433l128.194-67.382l128.19,67.382
                    c4.377,2.286,8.186,3.433,11.423,3.433c4.381,0,7.622-1.759,9.709-5.283c2.088-3.529,2.659-8.416,1.708-14.702l-24.551-142.749
                    l103.63-101.068C473.93,194.174,476.212,188.512,474.487,183.276z M338.597,275.065l-13.99,13.421l3.43,18.843l17.128,101.357
                    l-90.786-47.965l-16.848-8.856V76.927l45.395,91.933l8.559,17.128l18.85,2.856l101.642,14.844L338.597,275.065z"
                          />
                        </g>
                      </svg>
                    )}
                  </RatingStar>
                )
              })}
            </div>
            <RatingScore
              className={`col-4 col-md-2 ${
                noTitle ? 'col-lg-2' : 'col-lg-1'
              } font-weight-xl position-relative d-flex align-items-end`}
            >
              <RatingScoreNumber className="font-size-xxl">
                {/* {rating.toString().split('.')[0]} */}9
              </RatingScoreNumber>
              {/* {rating != maxRating && ( */}
              <>
                <RatingScoreSeperator className="font-size-xxl">
                  ,
                </RatingScoreSeperator>
                <RatingScoreDecimal className="font-size-xm">
                  {/* {rating.toString().split('.')[1] ? rating.toString().split('.')[1] : '0'} */}
                  5
                </RatingScoreDecimal>
              </>
              {/* )} */}
            </RatingScore>
            <div
              className={`${
                noTitle ? 'col-8' : 'col-12'
              } col-sm-5 col-lg-4 d-flex align-items-center`}
            >
              <RatingScoreStripe className="d-md-block d-none" />
              <RatingScoreAmountTitle
                noTitle={noTitle}
                className="font-size-xm font-weight-l text-uppercase ml-md-4"
              >
                {reviews.edges.length} Reviews
              </RatingScoreAmountTitle>
            </div>
          </div>
        </div>
      </CustomLink>
    </StyledRating>
  )
}

export default Rating
